<template>
  <div class="event-stake-form">
    <CRow>
      <CCol sm="3">
        <CSelect
          :value="item.dog_id"
          name="dog_id"
          label="Dog"
          :options="dogs"
          placeholder="Select Dog"
          @update:value="onInputChanged"
        />
      </CCol>
      <CCol sm="3">
        <CSelect
          :value="item.event_stake_id"
          name="event_stake_id"
          label="Stake"
          :options="stakes"
          placeholder="Select Stake"
          @update:value="onInputChanged"
        />
      </CCol>
      <CCol sm="4">
        <CSelect
          :value="item.handler_id"
          name="handler_id"
          label="Handler"
          :options="handlers"
          placeholder="Select Handler"
          @update:value="onInputChanged"
        />
      </CCol>
      <CCol sm="2">
        <div class="form-group">
          <label></label>
          <div class="form-control border-0">
            <CSpinner v-if="isAdding" color="primary" :grow="true" />
            <CButton v-if="!isAdding" @click="onAdd" color="primary">Add</CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'EventStakeForm',
  props: {
    event: Object,
    dogs: Array,
    stakes: Array,
    handlers: Array
  },
  data () {
    return {
      item: { event_stake_id: '', dog_id: '', handler_id: '' },
      isAdding: false
    }
  },
  methods: {
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
    },
    async onAdd () {
      this.isAdding = true
      await this.$store.dispatch('events/addEventEntry', { eventId: this.event.id, data: this.item })
      this.isAdding = false
    }
  }
}
</script>
