<template>
  <CModal
    v-if="!!item"
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      v-if="formEntity === 'default'"
      :data="item"
      :errors="errors"
      :locationLists="selectionLists.eventLocationsLists"
      :clubLists="selectionLists.clubsLists"
      :secretariesLists="selectionLists.secretariesLists"
      @itemChange="onItemChange"
      @formChange="onFormChange"
    />
    <ClubForm
      v-if="formEntity === 'clubs'"
      :key="addForms.clubs.key"
      :errors="addForms.clubs.errors"
      @itemChange="onAddFormItemChange"
    />
    <EventLocationForm
      v-if="formEntity === 'event_locations'"
      :key="addForms.eventLocations.key"
      :errors="addForms.eventLocations.errors"
      @itemChange="onAddFormItemChange"
    />
    <SecretaryForm
      v-if="formEntity === 'secretaries'"
      :key="addForms.secretaries.key"
      :errors="addForms.secretaries.errors"
      @itemChange="onAddFormItemChange"
    />
    <template #header>
      <h3 v-if="formEntity === 'default'" class="modal-title">Edit  {{ $options.entitySingle }} - {{ item.id }}</h3>
      <h3 v-if="formEntity !== 'default'" class="modal-title">Create New {{ title }}</h3>
      <CButtonClose v-if="formEntity === 'default'" @click="onCancel" class="text-white" />
      <CButtonClose v-if="formEntity !== 'default'" @click="backToDefault" class="text-white" />
    </template>
    <template #footer>
      <form-create-button
        v-if="formEntity !== 'default'"
        :showLoading="addForms[formEntityCamelCase].isSubmitting"
        :showCreateClose="false"
        @insertNew="onInsertAdditional"
        @cancel="backToDefault"
      />
      <form-update-button
        v-if="formEntity === 'default'"
        :showLoading="isSubmitting"
        @update="onUpdate"
        @updateAndClose="onUpdateAndClose"
        @cancel="onCancel"
      />
    </template>
  </CModal>
</template>

<script>
import generateKey from '@/utils/generateKey'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import { mapActions, mapState } from 'vuex'

import Form from './Form'
import ClubForm from '@/views/Club/components/Form'
import EventLocationForm from '@/views/EventLocation/components/Form'
import SecretaryForm from '@/views/Secretary/components/Form'

import { entity, entitySingle } from '../entity'

export default {
  name: 'Update',
  components: {
    Form,
    EventLocationForm,
    ClubForm,
    SecretaryForm
  },
  entitySingle: startCase(entitySingle),
  data () {
    return {
      editItem: null,
      addForms: {
        eventLocations: this.formData(),
        clubs: this.formData(),
        secretaries: this.formData()
      },
      title: this.$options.entitySingle,
      formEntity: 'default',
      formEntityCamelCase: 'default'
    }
  },
  computed: {
    ...mapState({
      item: state => state[entity].edit.data,
      isSubmitting: state => state[entity].edit.submitting,
      errors: state => state[entity].edit.errors,
      selectionLists: state => state[entity].selections
    })
  },
  methods: {
    ...mapActions({
      updateItem: `${entity}/updateItem`,
      loadSelectionLists: `${entity}/loadSelectionLists`,
      insertSelectionList: `${entity}/insertSelectionList`
    }),
    formData () {
      return {
        key: generateKey(),
        data: {},
        errors: {},
        isSubmitting: false
      }
    },
    onItemChange (item) {
      this.editItem = item
    },
    onAddFormItemChange (data) {
      const { formEntityCamelCase: formEntity } = this
      this.addForms = { ...this.addForms, [formEntity]: { ...this.addForms[formEntity], data } }
    },
    onFormChange (form) {
      this.formEntity = form
      this.formEntityCamelCase = camelCase(form)
      if (form === 'default') {
        this.title = this.$options.entitySingle
        return
      }
      this.title = startCase(form)
      this.addForms = { ...this.addForms, [this.formEntityCamelCase]: this.formData() }
    },
    async onInsertAdditional () {
      const { formEntity: entity, formEntityCamelCase: entityCamelCase } = this
      const { data } = this.addForms[entityCamelCase]
      const { success, errorFields } = await this.insertSelectionList({ entity, data })
      if (success) {
        this.onFormChange(entity)
      } else if (errorFields) {
        this.addForms = { ...this.addForms, [entityCamelCase]: { ...this.addForms[entityCamelCase], errors: errorFields } }
      }
    },
    async onUpdate () {
      const { editItem, item } = this
      if (editItem) {
        const data = Object.assign({}, editItem)
        data.club_id = (data.club || {}).id
        data.event_location_id = (data.event_location || {}).id
        data.secretary_id = (data.secretary || {}).id

        delete data.club
        delete data.event_location
        delete data.secretary

        await this.updateItem({ id: item.id, data, successClose: false })
      }
    },
    async onUpdateAndClose () {
      const { editItem, item } = this
      if (editItem) {
        const data = Object.assign({}, editItem)
        data.club_id = (data.club || {}).id
        data.event_location_id = (data.event_location || {}).id
        data.secretary_id = (data.secretary || {}).id

        delete data.club
        delete data.event_location
        delete data.secretary

        await this.updateItem({ id: item.id, data, successClose: true })
        return
      }
      this.onCancel()
    },
    backToDefault () {
      this.onFormChange('default')
    },
    onCancel () {
      this.$store.commit(`${entity}/EDIT_DATA`, false)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
    this.loadSelectionLists({ entity: 'event_locations' })
    this.loadSelectionLists({ entity: 'clubs' })
    this.loadSelectionLists({ entity: 'secretaries' })
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
