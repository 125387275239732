import axios from '@/utils/axios'
import commonActions from '@/common/store/actions'
import camelCase from 'lodash/camelCase'
import lowerCase from 'lodash/lowerCase'

const rootDispatch = { root: true }
const customActions = {
  async loadSelectionLists ({ commit, dispatch }, { entity, uid = '' }) {
    const { type, payload } = await axios({ url: `${entity}/lists/active`, method: 'GET', params: { uid } })
    if (type === 200) {
      const { data } = payload
      commit('LOAD_SELECTION_LISTS', { entity: camelCase(entity), data })
      return
    }
    commit('LOAD_SELECTION_LISTS', { entity: camelCase(entity), data: [] })
    dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
  },
  async insertSelectionList ({ dispatch }, { entity, data }) {
    const { type, payload } = await axios({ url: `${entity}`, method: 'POST', data })
    if (type === 200) {
      dispatch('loadSelectionLists', { entity, uid: data.uid || '' })
      dispatch('addNotification', { type: 'success', message: `New ${lowerCase(entity)} added successfully.` }, rootDispatch)
      return { success: true }
    } else if (type === 422) {
      const { errors } = payload
      const errorFields = Object.keys(errors).reduce((retData, curKey) => {
        retData[curKey] = errors[curKey][0]
        return retData
      }, {})
      return { success: false, errorFields }
    }
    dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    return { success: false }
  },
  async loadEventStakes ({ commit, dispatch }, { eventId }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_stakes`, method: 'GET' })
    if (type === 200) {
      commit('EVENT_STAKE_LISTS', payload.data)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadStakes ({ commit, dispatch }, { eventId }) {
    commit('IS_FETCHING', { key: 'eventStakes', isFetching: true })
    const { type, payload } = await axios({ url: `events/${eventId}/event_stakes/stakeOptions`, method: 'GET' })
    if (type === 200) {
      const stakeSelections = payload.map(e => ({ value: e.id, label: `${e.description} (${e.code})` }))
      commit('EVENT_STAKE_SELECTION', stakeSelections)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
    commit('IS_FETCHING', { key: 'eventStakes', isFetching: false })
  },
  async addEventStake ({ state, commit, dispatch }, { eventId, data }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_stakes`, method: 'POST', data })
    if (type === 200) {
      const { event } = state.eventStakes
      commit('SHOW_EVENT_STAKES', { event })
      dispatch('addNotification', { type: 'success', message: 'Event stake successfully added.' }, rootDispatch)
    } else if (type === 422) {
      const { errors } = payload
      const [firstKey] = Object.keys(errors)
      dispatch('addNotification', { type: 'error', message: errors[firstKey][0] }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async updateEventStake ({ dispatch }, { eventId, eventStakeId, data }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_stakes/${eventStakeId}`, method: 'PATCH', data })
    if (type === 200) {
      dispatch('loadEventStakes', { eventId })
      dispatch('addNotification', { type: 'success', message: 'Event stake successfully updated.' }, rootDispatch)
    } else if (type === 422) {
      const { errors } = payload
      const [firstKey] = Object.keys(errors)
      dispatch('addNotification', { type: 'error', message: errors[firstKey][0] }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async deleteEventStake ({ state, commit, dispatch }, { eventId, eventStakeId }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_stakes/${eventStakeId}`, method: 'DELETE' })
    if (type === 200) {
      const { event } = state.eventStakes
      commit('SHOW_EVENT_STAKES', { event })
      dispatch('addNotification', { type: 'success', message: 'Event stake successfully deleted.' }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadEventEntries ({ commit, dispatch }, { eventId }) {
    commit('IS_FETCHING', { key: 'eventEntries', isFetching: true })
    const { type, payload } = await axios({ url: `events/${eventId}/event_entries`, method: 'GET' })
    if (type === 200) {
      commit('EVENT_ENTRIES_LISTS', payload.data)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
    commit('IS_FETCHING', { key: 'eventEntries', isFetching: false })
  },
  async loadEntryStakes ({ commit, dispatch }, { eventId }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_entries/stakeOptions`, method: 'GET' })
    if (type === 200) {
      const stakeSelections = payload.map(e => ({ value: e.id, label: `${e.description} (${e.code}) - $${e.stake_price}` }))
      commit('EVENT_ENTRIES_SELECTION', { key: 'stakes', data: stakeSelections })
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadEntryDogs ({ commit, dispatch }, { eventId }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_entries/dogOptions`, method: 'GET' })
    if (type === 200) {
      const dogSelections = payload.map(e => ({ value: e.id, label: e.registered_name }))
      commit('EVENT_ENTRIES_SELECTION', { key: 'dogs', data: dogSelections })
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadEntryHandlers ({ commit, dispatch }) {
    const { type, payload } = await axios({ url: 'handlers/handlerOptions', method: 'GET' })
    if (type === 200) {
      const handlerOptions = payload.map(e => ({ value: e.id, label: e.name }))
      commit('EVENT_ENTRIES_SELECTION', { key: 'handlers', data: handlerOptions })
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async addEventEntry ({ state, commit, dispatch }, { eventId, data }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_entries`, method: 'POST', data })
    if (type === 200) {
      const { event } = state.eventEntries
      commit('SHOW_EVENT_ENTRIES', { event })
      dispatch('loadLists')
      dispatch('addNotification', { type: 'success', message: 'Event entry successfully added.' }, rootDispatch)
    } else if (type === 422) {
      const { errors } = payload
      const [firstKey] = Object.keys(errors)
      dispatch('addNotification', { type: 'error', message: errors[firstKey][0] }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async deleteEventEntry ({ state, commit, dispatch }, { eventId, eventEntryId }) {
    const { type, payload } = await axios({ url: `events/${eventId}/event_entries/${eventEntryId}`, method: 'DELETE' })
    if (type === 200) {
      const { event } = state.eventEntries
      commit('SHOW_EVENT_ENTRIES', { event })
      dispatch('loadLists')
      dispatch('addNotification', { type: 'success', message: 'Event entry successfully deleted.' }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  }
}

export default Object.assign(
  {},
  commonActions,
  customActions
)
