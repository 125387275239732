<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      :stakes="stakes"
      :event="event"
    />
    <List
      :event="event"
      :lists="lists"
      :fetching="isFetching"
    />
    <template #header>
      <h3 class="modal-title">Event Stakes - {{ event.name }}</h3>
      <CButtonClose @click="onCancel" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="onCancel" color="success">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex'

import Form from './Form'
import List from './List'

import { entity } from '@/views/Event/entity'

export default {
  name: 'EventStake',
  components: {
    Form,
    List
  },
  methods: {
    onCancel () {
      this.$store.commit(`${entity}/HIDE_EVENT_STAKES`)
    }
  },
  computed: {
    ...mapState({
      event: state => state[entity].eventStakes.event,
      lists: state => state[entity].eventStakes.lists,
      stakes: state => state[entity].eventStakes.stakes,
      isFetching: state => state[entity].eventStakes.isFetching
    })
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
    const { id: eventId } = this.event
    this.$store.dispatch(`${entity}/loadStakes`, { eventId })
    this.$store.dispatch(`${entity}/loadEventStakes`, { eventId })
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
