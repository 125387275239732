export default [
  {
    fieldKey: 'name',
    type: 'input',
    label: 'Event Name',
    default: ''
  },
  {
    fieldKey: 'akc_license_number',
    type: 'input',
    label: 'Akc License Number',
    default: ''
  },
  {
    fieldKey: 'event_type',
    type: 'select',
    label: 'Event Type',
    options: [
      { value: '', label: 'All Event Type' },
      { value: 'HT', label: 'Hunt Test' },
      { value: 'FT', label: 'Field Trial' }
    ],
    default: ''
  },
  {
    fieldKey: 'is_retrieving_stake',
    type: 'select',
    label: 'Retrieving Stake',
    options: [
      { value: '', label: 'Both' },
      { value: false, label: 'No' },
      { value: true, label: 'Yes' }
    ],
    default: ''
  },
  {
    fieldKey: 'is_completed',
    type: 'select',
    label: 'Status',
    options: [
      { value: '', label: 'Both' },
      { value: false, label: 'Incomplete' },
      { value: true, label: 'Completed' }
    ],
    default: ''
  }
]
