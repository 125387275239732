<template>
  <CForm autocomplete="off">
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.name"
          name="name"
          label="Event Name *"
          placeholder="Event Name"
          :isValid="!errors.name ? null : false"
          :invalidFeedback="errors.name"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.akc_license_number"
          name="akc_license_number"
          label="AKC Licensed Number *"
          placeholder="AKC Licensed Number"
          :isValid="!errors.akc_license_number ? null : false"
          :invalidFeedback="errors.akc_license_number"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CSelect
          :value="item.event_type"
          name="event_type"
          label="Event Type *"
          :options="$options.typeLists"
          :isValid="!errors.event_type ? null : false"
          :invalidFeedback="errors.event_type"
          @update:value="onInputChanged"
        />
        <p v-if="item.id" class="small text-danger">Updating event type will delete event stakes and entries.</p>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.open_date"
          name="open_date"
          label="Open Date *"
          placeholder="01/31/2021"
          :isValid="!errors.open_date ? null : false"
          :invalidFeedback="errors.open_date"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.close_date"
          name="close_date"
          label="Close Date *"
          placeholder="01/31/2021"
          :isValid="!errors.close_date ? null : false"
          :invalidFeedback="errors.close_date"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.start_date"
          name="start_date"
          label="Start Date *"
          placeholder="01/31/2021"
          :isValid="!errors.start_date ? null : false"
          :invalidFeedback="errors.start_date"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.end_date"
          name="end_date"
          label="End Date *"
          placeholder="01/31/2021"
          :isValid="!errors.end_date ? null : false"
          :invalidFeedback="errors.end_date"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="10">
        <div class="form-group">
          <label>Club *</label>
          <vue-list-select
            name="club"
            :list="clubLists"
            :selectedItem="item.club"
            option-value="id"
            option-text="club_name"
            placeholder="Select Event Club"
            :isError="errors.club_id"
            @select="(data) => { selectUpdateValue(data, 'club') }"
          />
        </div>
      </CCol>
      <CCol sm="2" class="mt-4">
        <CButton @click="onAddClub" color="info">Add</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="10">
        <div class="form-group">
          <label>Location *</label>
          <vue-list-select
            name="event_location"
            :list="locationLists"
            :selectedItem="item.event_location"
            option-value="id"
            option-text="name"
            placeholder="Select Event Location"
            :isError="errors.event_location_id"
            @select="(data) => { selectUpdateValue(data, 'event_location') }"
          />
        </div>
      </CCol>
      <CCol sm="2" class="mt-4">
        <CButton @click="onAddLocation" color="info">Add</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="10">
        <div class="form-group">
          <label>Secretary *</label>
          <vue-list-select
            name="secretary"
            :list="secretariesLists"
            :selectedItem="item.secretary"
            option-value="id"
            option-text="name"
            placeholder="Select Secretary"
            :isError="errors.secretary_id"
            @select="(data) => { selectUpdateValue(data, 'secretary') }"
          />
        </div>
      </CCol>
      <CCol sm="2" class="mt-4">
        <CButton @click="onAddSecretary" color="info">Add</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInputCheckbox
          name="is_retrieving_stake"
          :checked="item.is_retrieving_stake"
          @update:checked="onInputChanged"
          label="Retrieving Stake"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInputCheckbox
          name="is_completed"
          :checked="item.is_completed"
          @update:checked="onInputChanged"
          label="Completed"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import inputFields from '../inputFields'
import typeLists from '@/common/eventTypeLists'

export default {
  name: 'Form',
  typeLists,
  props: {
    data: {
      type: Object,
      defaultValue: {}
    },
    errors: {
      type: Object,
      defaultValue: {}
    },
    locationLists: {
      type: Array,
      defaultValue: []
    },
    clubLists: {
      type: Array,
      defaultValue: []
    },
    secretariesLists: {
      type: Array,
      defaultValue: []
    }
  },
  data () {
    const defaultFields = Object.keys(inputFields).reduce((data, key) => {
      const { defaultValue } = inputFields[key]
      data[key] = defaultValue
      return data
    }, {})
    const item = Object.assign({}, defaultFields, (this.data || {}))
    return {
      item
    }
  },
  methods: {
    preventSubmit (event) {
      event.preventSubmit()
      event.preventDefault()
      event.stopPropagation()
    },
    selectUpdateValue (data, field) {
      this.item = { ...this.item, [field]: data }
      this.onHandleChange()
    },
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      this.onHandleChange()
    },
    onAddClub () {
      this.onFormChange('clubs')
    },
    onAddLocation () {
      this.onFormChange('event_locations')
    },
    onAddSecretary () {
      this.onFormChange('secretaries')
    },
    onHandleChange () {
      this.$emit('itemChange', this.item)
    },
    onFormChange (form) {
      this.$emit('formChange', form)
    }
  }
}
</script>
