import commonState from '@/common/store/state'

import { entity, entitySingle } from '../entity'

const customState = {
  entity,
  entitySingle,
  sortData: { column: 'name', direction: 'asc' },
  selections: {
    clubsLists: [],
    eventLocationsLists: [],
    secretariesLists: []
  },
  eventStakes: {
    key: '',
    show: false,
    stakes: [],
    lists: [],
    isFetching: false
  },
  judges: {
    key: '',
    show: false,
    lists: [],
    isFetching: false
  },
  eventEntries: {
    key: '',
    show: false,
    stakes: [],
    dogs: [],
    handlers: [],
    lists: [],
    isFetching: false
  }
}

export default Object.assign(
  {},
  commonState,
  customState
)
