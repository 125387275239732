import commonMutations from '@/common/store/mutations'
import generateKey from '@/utils/generateKey'

const customMutations = {
  LOAD_SELECTION_LISTS (state, payload) {
    const { entity, data } = payload
    state.selections = { ...state.selections, [`${entity}Lists`]: data }
  },
  SHOW_EVENT_STAKES (state, payload) {
    const { event } = payload
    state.eventStakes = { ...state.eventStakes, key: generateKey(), show: true, event }
  },
  HIDE_EVENT_STAKES (state) {
    state.eventStakes = { ...state.eventStakes, show: false, event: null, lists: [] }
  },
  EVENT_STAKE_SELECTION (state, payload) {
    state.eventStakes = { ...state.eventStakes, stakes: payload }
  },
  EVENT_STAKE_LISTS (state, payload) {
    state.eventStakes = { ...state.eventStakes, lists: payload }
  },
  EVENT_STAKE_FETCHING (state, payload) {
    state.eventStakes = { ...state.eventStakes, isFetching: payload }
  },
  SHOW_EVENT_JUDGES (state, payload) {
    const { event } = payload
    state.judges = { ...state.judges, show: true, event }
  },
  HIDE_EVENT_JUDGES (state, payload) {
    state.judges = { ...state.judges, show: false, event: null, lists: [] }
  },
  EVENT_JUDGES_FETCHING (state, payload) {
    state.judges = { ...state.judges, isFetching: payload }
  },
  SHOW_EVENT_ENTRIES (state, payload) {
    const { event } = payload
    state.eventEntries = { ...state.eventEntries, key: generateKey(), show: true, event }
  },
  HIDE_EVENT_ENTRIES (state) {
    state.eventEntries = { ...state.eventEntries, show: false, event: null, lists: [] }
  },
  EVENT_ENTRIES_SELECTION (state, payload) {
    const { key, data } = payload
    state.eventEntries = { ...state.eventEntries, [key]: data }
  },
  EVENT_ENTRIES_LISTS (state, payload) {
    state.eventEntries = { ...state.eventEntries, lists: payload }
  },
  EVENT_ENTRIES_FETCHING (state, payload) {
    state.eventEntries = { ...state.eventEntries, isFetching: payload }
  },
  IS_FETCHING (state, payload) {
    const { key, isFetching } = payload
    state[key] = { ...state[key], isFetching }
  }
}

export default Object.assign(
  {},
  commonMutations,
  customMutations
)
