<template>
  <div class="event-stake-form">
    <CRow>
      <CCol sm="8">
        <CSelect
          :value="item.stake_id"
          name="stake_id"
          label="Stake"
          :options="stakes"
          placeholder="Select Stake"
          @update:value="onInputChanged"
        />
      </CCol>
      <CCol sm="2">
        <CInput
          label="Price ($)"
          v-model="item.stake_price"
        />
      </CCol>
      <CCol sm="2">
        <div class="form-group">
          <label></label>
          <div class="form-control border-0">
            <CButton @click="onAdd" color="primary">Add</CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'EventStakeForm',
  props: {
    event: Object,
    stakes: Array
  },
  data () {
    return {
      item: { stake_id: '', stake_price: '' },
      isAdding: false
    }
  },
  methods: {
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
    },
    async onAdd () {
      this.isAdding = true
      await this.$store.dispatch('events/addEventStake', { eventId: this.event.id, data: this.item })
      this.isAdding = false
    }
  }
}
</script>
