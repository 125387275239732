<template>
  <div class="lists-table">
    <CDataTable
      :key="key"
      :hover="true"
      :striped="true"
      :border="true"
      :small="true"
      :fixed="true"
      :items="items"
      :fields="fields"
      :dark="false"
      :loading="fetching"
      addTableClasses="header-fixed"
    >
      <template #action="{item}">
        <td>
          <CButtonGroup size="sm">
            <CButton color="danger" @click="() => onDeleteItem(item)">Delete</CButton>
          </CButtonGroup>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
import generateKey from '@/utils/generateKey'
export default {
  name: 'EventStakeList',
  props: {
    event: Object,
    lists: Array,
    fetching: Boolean
  },
  data () {
    return {
      items: this.lists,
      fields: [
        'dog',
        'stake',
        'handler',
        { key: 'stake_price', label: 'Price ($)' },
        { key: 'action', label: '' }
      ],
      key: generateKey()
    }
  },
  methods: {
    onDeleteItem (item) {
      this.$store.dispatch('events/deleteEventEntry', { eventId: this.event.id, eventEntryId: item.id })
    }
  },
  watch: {
    lists () {
      this.items = this.lists
    }
  }
}
</script>
