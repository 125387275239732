var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{attrs:{"name":_vm.tableIcon}}),_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('CButtonGroup',{staticClass:"float-right"},[_c('CButton',{attrs:{"size":"sm","color":"primary"},on:{"click":_vm.onAddNew}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Add New ")],1),_c('CButton',{attrs:{"size":"sm","color":"success"},on:{"click":_vm.onRefresh}},[_c('CIcon',{attrs:{"name":"cil-action-redo"}}),_vm._v(" Refresh ")],1)],1)])],2),_c('CCardBody',[_c('PageFilter',{attrs:{"filterData":_vm.filterData,"onFilterUpdate":_vm.onFilter}}),_c('list-pagination',{attrs:{"pageData":_vm.pageData,"perPageSelectData":_vm.perPageSelect,"entity":_vm.entity,"containerClass":"header-pagination"},on:{"pageChange":_vm.changePage}}),_c('div',{staticClass:"lists-table"},[_c('CDataTable',{attrs:{"hover":true,"striped":true,"border":true,"small":true,"fixed":true,"items":_vm.lists,"fields":_vm.fields,"dark":false,"loading":_vm.fetching,"clickableRows":true,"addTableClasses":"header-fixed","sorter":{ external: true },"sorterValue":_vm.sorterValue},on:{"row-clicked":_vm.rowClicked,"update:sorter-value":_vm.updateSort},scopedSlots:_vm._u([{key:"event_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.event_type_text)+" ")])]}},{key:"num_entries",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.num_entries))]),_c('CLink',{on:{"click":function () { return _vm.showEntries(item); }}},[_vm._v("(View Details)")])],1)]}},{key:"is_retrieving_stake",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":item.is_retrieving_stake ? 'success' : 'danger'}},[_c('CIcon',{attrs:{"name":item.is_retrieving_stake ? 'cil-check-alt' : 'cil-x'}})],1)],1)]}},{key:"completed",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":item.is_completed ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.is_completed ? 'Completed' : 'Incomplete')+" ")])],1)]}},{key:"user",fn:function(ref){
var item = ref.item;
return [_c('td',{directives:[{name:"start-case",rawName:"v-start-case"}]},[_vm._v(" "+_vm._s(item.user.name)+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButtonGroup',{attrs:{"vertical":true,"size":"sm"}},[(item.num_entries > 0)?_c('CButton',{attrs:{"color":"success"},on:{"click":function () { return _vm.onGeneratePDF(item); }}},[_vm._v("Generate PDF")]):_vm._e(),_c('CButton',{attrs:{"color":"info"},on:{"click":function () { return _vm.onShowStakes(item); }}},[_vm._v("Stakes")]),_c('CButton',{attrs:{"color":"primary"},on:{"click":function () { return _vm.onEditItem(item); }}},[_vm._v("Edit")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function () { return _vm.onDeleteItem(item); }}},[_vm._v("Delete")])],1)],1)]}}])})],1),_c('list-pagination',{attrs:{"pageData":_vm.pageData,"perPageSelectData":_vm.perPageSelect,"entity":_vm.entity,"containerClass":"footer-pagination mt-2"},on:{"pageChange":_vm.changePage}})],1)],1),(_vm.newData.show)?_c('Create',{key:_vm.newData.key}):_vm._e(),(!!_vm.editData.data)?_c('Update',{key:_vm.editData.key}):_vm._e(),(!!_vm.deleteData.data)?_c('Delete',{key:_vm.deleteData.key}):_vm._e(),(_vm.detailData.data)?_c('Detail',{key:_vm.detailData.key}):_vm._e(),(_vm.eventStakeData.show)?_c('EventStake',{key:_vm.eventStakeData.key}):_vm._e(),(_vm.eventEntryData.show)?_c('EventEntry',{key:_vm.eventEntryData.key}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }