<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <template #header>
      <h3 class="modal-title">{{ $options.entitySingle }} Detail - {{ item.id }}</h3>
      <CButtonClose @click="hideForm" class="text-white"/>
    </template>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Event Name</label>
          <strong class="d-block border px-3 py-2">{{ item.name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>AKC Licensed Number</label>
          <strong class="d-block border px-3 py-2">{{ item.akc_license_number }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Event Type</label>
          <strong class="d-block border px-3 py-2">{{ item.event_type_text }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Open Date</label>
          <strong class="d-block border px-3 py-2">{{ dateFormat(item.open_date) }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Close Date</label>
          <strong class="d-block border px-3 py-2">{{ dateFormat(item.close_date) }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Start Date</label>
          <strong class="d-block border px-3 py-2">{{ dateFormat(item.start_date) }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>End Date</label>
          <strong class="d-block border px-3 py-2">{{ dateFormat(item.end_date) }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Club</label>
          <strong class="d-block border px-3 py-2">{{ item.club.club_name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Location</label>
          <strong class="d-block border px-3 py-2">{{ item.event_location.name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Secretary</label>
          <strong class="d-block border px-3 py-2">{{ item.secretary.name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CBadge :color="item.is_retrieving_stake ? 'success' : 'danger'" size="sm">
          <CIcon :name="item.is_retrieving_stake ? 'cil-check-alt' : 'cil-x'" />
        </CBadge>
        <span class="ml-2">Retrieving Stake</span>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
      <CBadge :color="item.is_completed ? 'success' : 'danger'" size="sm">
          <CIcon :name="item.is_completed ? 'cil-check-alt' : 'cil-x'" />
        </CBadge>
        <span class="ml-2">Completed</span>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hideForm" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import moment from 'moment'
import startCase from 'lodash/startCase'
import { mapState } from 'vuex'
import { entity, entitySingle } from '../entity'

export default {
  name: 'Detail',
  entitySingle: startCase(entitySingle),
  computed: {
    ...mapState({
      item: state => state[entity].detail.data
    })
  },
  methods: {
    hideForm () {
      this.$store.commit(`${entity}/DETAIL_DATA`, null)
    },
    dateFormat (date) {
      return moment(date).format('MM/DD/YYYY')
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
