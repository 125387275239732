export default [
  'name',
  'akc_license_number',
  'event_type',
  'open_date',
  'start_date',
  { key: 'num_entries', label: 'No. Entries', sorter: false },
  { key: 'is_retrieving_stake', label: 'Retrieving Stake', sorter: true },
  { key: 'completed', label: 'Completed', sorter: false },
  { key: 'user', label: 'Owner', sorter: false },
  { key: 'action', label: '' }
]
