<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      :stakes="stakes"
      :dogs="dogs"
      :handlers="handlers"
      :event="event"
    />
    <List
      :event="event"
      :lists="lists"
      :fetching="isFetching"
    />
    <template #header>
      <h3 class="modal-title">Event Entries - {{ event.name }}</h3>
      <CButtonClose @click="onCancel" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="onCancel" color="success">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex'

import Form from './Form'
import List from './List'

import { entity } from '@/views/Event/entity'

export default {
  name: 'EventEntry',
  components: {
    Form,
    List
  },
  methods: {
    onCancel () {
      this.$store.commit(`${entity}/HIDE_EVENT_ENTRIES`)
    }
  },
  computed: {
    ...mapState({
      dogs: state => state[entity].eventEntries.dogs,
      stakes: state => state[entity].eventEntries.stakes,
      handlers: state => state[entity].eventEntries.handlers,
      event: state => state[entity].eventEntries.event,
      lists: state => state[entity].eventEntries.lists,
      isFetching: state => state[entity].eventEntries.isFetching
    })
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
    const { id: eventId } = this.event
    this.$store.dispatch(`${entity}/loadEntryStakes`, { eventId })
    this.$store.dispatch(`${entity}/loadEntryDogs`, { eventId })
    this.$store.dispatch(`${entity}/loadEntryHandlers`, { eventId })
    this.$store.dispatch(`${entity}/loadEventEntries`, { eventId })
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
