<template>
  <div class="lists-table">
    <CDataTable
      :key="key"
      :hover="true"
      :striped="true"
      :border="true"
      :small="true"
      :fixed="true"
      :items="items"
      :fields="fields"
      :dark="false"
      :loading="fetching"
      addTableClasses="header-fixed"
    >
      <template #stake="{item}">
        <td>
          {{ item.stake.description }} ({{ item.stake.code }})
        </td>
      </template>
      <template #price="{item}">
        <td v-if="!item.showEdit">
          <span>${{ item.stake_price }}</span>
        </td>
        <td v-if="item.showEdit">
          $ <input type="text" class="form-control d-inline-block w-25" v-model="item.edit_stake_price" />
        </td>
      </template>
      <template #action="{item}">
        <td>
          <CButtonGroup v-if="!item.showEdit" size="sm">
            <CButton color="primary" @click="() => onEditItem(item)">Edit</CButton>
            <CButton color="danger" @click="() => onDeleteItem(item)">Delete</CButton>
          </CButtonGroup>
          <CButtonGroup v-if="item.showEdit" size="sm">
            <CButton color="success" @click="() => onUpdateItem(item)">Update</CButton>
            <CButton color="danger" @click="() => onEditCancel(item)">Cancel</CButton>
          </CButtonGroup>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
import generateKey from '@/utils/generateKey'
export default {
  name: 'EventStakeList',
  props: {
    event: Object,
    lists: Array,
    fetching: Boolean
  },
  data () {
    return {
      items: this.lists,
      fields: ['stake', 'price', { key: 'action', label: '' }],
      key: generateKey()
    }
  },
  methods: {
    onEditItem (item) {
      const idx = this.items.findIndex(e => e.id === item.id)
      this.items[idx] = { ...item, edit_stake_price: item.stake_price, showEdit: true }
      this.key = generateKey()
    },
    onEditCancel (item) {
      const idx = this.items.findIndex(e => e.id === item.id)
      this.items[idx] = { ...item, showEdit: false }
      this.key = generateKey()
    },
    onUpdateItem (item) {
      const data = {
        eventId: this.event.id,
        eventStakeId: item.id,
        data: { stake_price: item.edit_stake_price }
      }
      this.$store.dispatch('events/updateEventStake', data)
    },
    onDeleteItem (item) {
      this.$store.dispatch('events/deleteEventStake', { eventId: this.event.id, eventStakeId: item.id })
    }
  },
  watch: {
    lists () {
      this.items = this.lists
    }
  }
}
</script>
