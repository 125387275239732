export default {
  name: { defaultValue: '' },
  akc_license_number: { defaultValue: '' },
  open_date: { defaultValue: '' },
  start_date: { defaultValue: '' },
  close_date: { defaultValue: '' },
  end_date: { defaultValue: '' },
  club: { defaultValue: { id: '', club_name: '' } },
  event_location: { defaultValue: { id: '', name: '' } },
  secretary: { defaultValue: { id: '', name: '' } },
  event_type: { defaultValue: 'HT' },
  is_retrieving_stake: { defaultValue: false },
  is_completed: { defaultValue: false }
}
